import {
  faSearch,
  faUser,
  faShoppingCart,
  faPhone,
  faChevronRight,
  faChevronLeft,
  faHouse,
  faPlus,
  faMinus
} from '@fortawesome/pro-solid-svg-icons'

import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons'

const icons = [
  faSearch,
  faUser,
  faShoppingCart,
  faPhone,
  faChevronRight,
  faChevronLeft,
  faHouse,
  faPlus,
  faMinus,
  faSquare,
  faSquareCheck
]

export default {
  icons
}
