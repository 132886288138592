<template>
  <l-section element="footer">
    <l-container content>
      <div class="footer-container">
        <div class="company-info">
          <the-logo large />
          <div class="content">
            <ul class="col">
              <li>Buvac B.V.</li>
              <li>Meerheide 106</li>
              <li>5521 DX, Eersel</li>
            </ul>
            <ul class="col">
              <li>
                Tel:
                <a class="hoverable" href="tel:+31497382748"
                  >+31 (0)497 382 748</a
                >
              </li>
              <li>
                E-mail:
                <a class="hoverable" href="mailto:info@buvac.nl"
                  >info@buvac.nl</a
                >
              </li>
            </ul>
          </div>
        </div>
        <nav class="footer-nav content">
          <ul class="col">
            <li v-for="(link, i) in linksColumnLeft" :key="i">
              <a class="hoverable" :href="link.href">
                {{ link.name }}
              </a>
            </li>
          </ul>
          <ul class="col">
            <li v-for="(link, i) in linksColumnRight" :key="i">
              <a class="hoverable" :href="link.href">{{ link.name }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </l-container>
  </l-section>
</template>

<script>
  import Vue from 'vue'
  import LContainer from '@/components/base/layout/LContainer.vue'
  import LSection from '@/components/base/layout/LSection.vue'
  import TheLogo from '@/components/base/TheLogo.vue'

  /**
   * FooterBlock
   * @displayName: FooterBlock
   */
  export default Vue.extend({
    name: 'FooterBlock',
    components: {
      TheLogo,
      LContainer,
      LSection
    },
    props: {
      footerLinks: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      linksColumnLeft() {
        const half = Math.ceil(this.footerLinks.length / 2)
        return [...this.footerLinks].splice(-half)
      },
      linksColumnRight() {
        const half = Math.ceil(this.footerLinks.length / 2)
        return [...this.footerLinks].splice(0, half)
      }
    }
  })
</script>

<style scoped lang="scss">
  .footer-container {
    display: flex;
    flex-direction: column-reverse;
    line-height: 1.3rem;
    gap: 2rem;
    justify-content: space-between;
  }

  .company-info {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: space-between;

    .col {
      margin-right: 1.5rem;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .footer-nav {
    line-height: 2.6rem;

    > .col {
      align-items: center;
    }
  }

  @media only screen and (min-width: 660px) {
    .footer-container {
      line-height: 2.6rem;
    }

    .content {
      display: flex;
      flex-direction: row;
      gap: 3rem;
    }

    .company-info {
      gap: 3rem;
      justify-content: flex-start;
    }

    .footer-nav {
      justify-content: center;
      align-items: center;

      > .col {
        align-items: flex-start;
      }
    }
  }

  @media only screen and (min-width: 1110px) {
    .footer-container {
      flex-direction: row;
      line-height: 2.6rem;
    }
  }
</style>
