// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/logo/Buvac-logo-157.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo[data-v-20b18359]{display:flex;flex:0 0 auto;background-size:contain;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:50px;aspect-ratio:1}.logo.large[data-v-20b18359]{width:125px}@media only screen and (min-width:600px)and (orientation:landscape){.logo[data-v-20b18359]:not(.small,.large){width:60px}}@media only screen and (min-width:600px)and (orientation:portrait){.logo[data-v-20b18359]:not(.small):not(.large){width:100px}}@media only screen and (min-width:1120px){.logo[data-v-20b18359]:not(.small):not(.large){width:100px}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
