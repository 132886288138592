import { render, staticRenderFns } from "./ProductSubCategoryCard.vue?vue&type=template&id=9073d5f4&scoped=true&"
import script from "./ProductSubCategoryCard.vue?vue&type=script&lang=ts&"
export * from "./ProductSubCategoryCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductSubCategoryCard.vue?vue&type=style&index=0&id=9073d5f4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9073d5f4",
  null
  
)

export default component.exports