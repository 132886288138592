<template>
  <example-page v-bind="exampleProps" />
</template>

<script>
  import ExamplePage from '@/pages/ExamplePage'
  import { v4 as uuidv4 } from 'uuid'
  import { LoremIpsum } from 'lorem-ipsum'
  import kebabCase from 'lodash.kebabcase'

  const lorem = new LoremIpsum({
    sentencesPerParagraph: {
      max: 8,
      min: 4
    },
    wordsPerSentence: {
      max: 16,
      min: 4
    }
  })

  export default {
    name: 'App',
    components: {
      ExamplePage
    },
    data() {
      return {
        exampleProps: {
          checkout: {
            breadcrumbs: [
              {
                name: 'Winkelwagen',
                href: '/winkelwagen'
              }
            ]
          },
          productCatalog: {
            filters: [
              {
                id: uuidv4(),
                type: 'categorie',
                title: 'categorie',
                items: [
                  {
                    id: uuidv4(),
                    title: 'machines',
                    param: { key: 'categorie', value: 'machines' }
                  },
                  {
                    id: uuidv4(),
                    title: 'schuren',
                    param: { key: 'categorie', value: 'schuren' }
                  },
                  {
                    id: uuidv4(),
                    title: 'borstelen',
                    param: { key: 'categorie', value: 'borstelen' }
                  },
                  {
                    id: uuidv4(),
                    title: 'zagen',
                    param: { key: 'categorie', value: 'zagen' }
                  }
                ]
              },
              {
                id: uuidv4(),
                type: 'product_type',
                title: 'product type',
                items: [
                  {
                    id: uuidv4(),
                    title: 'apparatuur',
                    param: { key: 'product_type', value: 'apparatuur' }
                  },
                  {
                    id: uuidv4(),
                    title: 'toebehoren',
                    param: { key: 'product_type', value: 'toebehoren' }
                  }
                ]
              }
            ],
            breadcrumbs: [
              {
                name: 'schuren',
                href: '/schuren'
              }
            ],
            items: [
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/5251010000000.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/PTX802HT.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/VARILEXAKKUPROHT.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/rolei_be5_5.jpg'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/rolei_be5_5.jpg'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/053d4a2573.jpg'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/GRIT-GX752H.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/GRIT-GXR.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/053d4a2573.jpg'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/GRIT-GX752H.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/GRIT-GXR.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/GRIT-GXE.JPG'
                }
              }
            ],
            pagination: {
              pages: 5
            }
          },
          searchResults: {
            breadcrumbs: [
              {
                name: 'Zoeken',
                href: '/zoeken'
              }
            ],
            products: {
              catalog: {
                name: 'Zoek producten',
                href: '/'
              },
              items: [
                {
                  id: uuidv4(),
                  name: 'Lorem ipsum',
                  minimumPrice: Math.floor(Math.random() * 110) + 50,
                  maximumPrice: Math.floor(Math.random() * 200) + 100,
                  href: '#',
                  image: {
                    src: 'http://webshop.buvac.nl/acpecat/images/5251010000000.JPG'
                  }
                },
                {
                  id: uuidv4(),
                  name: 'Lorem ipsum',
                  minimumPrice: Math.floor(Math.random() * 110) + 50,
                  maximumPrice: Math.floor(Math.random() * 200) + 100,
                  href: '#',
                  image: {
                    src: 'http://webshop.buvac.nl/acpecat/images/PTX802HT.JPG'
                  }
                },
                {
                  id: uuidv4(),
                  name: 'Lorem ipsum',
                  minimumPrice: Math.floor(Math.random() * 110) + 50,
                  maximumPrice: Math.floor(Math.random() * 200) + 100,
                  href: '#',
                  image: {
                    src: 'http://webshop.buvac.nl/acpecat/images/VARILEXAKKUPROHT.JPG'
                  }
                },
                {
                  id: uuidv4(),
                  name: 'Lorem ipsum',
                  minimumPrice: Math.floor(Math.random() * 110) + 50,
                  maximumPrice: Math.floor(Math.random() * 200) + 100,
                  href: '#',
                  image: {
                    src: 'http://webshop.buvac.nl/acpecat/images/rolei_be5_5.jpg'
                  }
                }
              ]
            },
            messages: [
              {
                id: uuidv4(),
                title: lorem.generateWords(3),
                content: lorem.generateParagraphs(2),
                href: kebabCase(lorem.generateWords(3))
              },
              {
                id: uuidv4(),
                title: lorem.generateWords(3),
                content: lorem.generateParagraphs(2),
                href: kebabCase(lorem.generateWords(3))
              },
              {
                id: uuidv4(),
                title: lorem.generateWords(3),
                content: lorem.generateParagraphs(2),
                href: kebabCase(lorem.generateWords(3))
              },
              {
                id: uuidv4(),
                title: lorem.generateWords(3),
                content: lorem.generateParagraphs(2),
                href: kebabCase(lorem.generateWords(3))
              },
              {
                id: uuidv4(),
                title: lorem.generateWords(3),
                content: lorem.generateParagraphs(2),
                href: kebabCase(lorem.generateWords(3))
              }
            ],
            pagination: {
              pages: 50
            }
          },
          recentProducts: {
            title:
              '<h2 class="z-1"> <span class="text-orange">RECENTE</span> <span>PRODUCTEN</span> </h2>',
            items: [
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/5251010000000.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/PTX802HT.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/VARILEXAKKUPROHT.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/rolei_be5_5.jpg'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/rolei_be5_5.jpg'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/053d4a2573.jpg'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/GRIT-GX752H.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/GRIT-GXR.JPG'
                }
              },
              {
                id: uuidv4(),
                name: 'Lorem ipsum',
                minimumPrice: Math.floor(Math.random() * 110) + 50,
                maximumPrice: Math.floor(Math.random() * 200) + 100,
                href: '#',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/GRIT-GXE.JPG'
                }
              }
            ]
          },
          header: {
            content: `<div class='text-4xl weight-normal z-1'>
              <p class='text-orange'>Gereedschappen</p>
              <p>en
              <span class='text-orange'>machines</span></p>
              <p>voor de finishing touch!</p>
            </div>`,
            image: {
              src: 'https://images.unsplash.com/photo-1611021061421-93741ec41ce1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80'
            }
          },
          mainMenuItems: [
            { name: 'Schuren', href: '/schuren' },
            { name: 'Slijpen', href: '/slijpen' },
            { name: 'Machines', href: '/machines' },
            { name: 'Ontbramen', href: '/ontbramen' },
            { name: 'Satineren', href: '/satineren' },
            { name: 'Zagen', href: '/zagen' },
            { name: 'Verspanen', href: '/verspanen' },
            { name: 'Borstelen', href: '/borstelen' },
            { name: 'Polijsten', href: '/polijsten' },
            { name: 'Glasbewerking', href: '/glasbewerking' }
          ],
          productCategories: {
            title:
              '<h2 class="z-1"> <span class="text-orange">PRODUCT</span> <span>CATEGORIEËN</span> </h2>',
            items: [
              {
                name: 'Schuren',
                href: '/schuren',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/PTX802HT.JPG'
                }
              },
              {
                name: 'Slijpen',
                href: '/slijpen',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/5251010000000.JPG'
                }
              },
              {
                name: 'Machines',
                href: '/machines',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/VARILEXAKKUPROHT.JPG'
                }
              },
              {
                name: 'Ontbramen',
                href: '/ontbramen',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/PTX802HT.JPG'
                }
              },
              {
                name: 'Satineren',
                href: '/satineren',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/5251010000000.JPG'
                }
              },
              {
                name: 'Zagen',
                href: '/zagen',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/VARILEXAKKUPROHT.JPG'
                }
              },
              {
                name: 'Verspanen',
                href: '/verspanen',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/PTX802HT.JPG'
                }
              },
              {
                name: 'Borstelen',
                href: '/borstelen',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/5251010000000.JPG'
                }
              },
              {
                name: 'Polijsten',
                href: '/polijsten',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/VARILEXAKKUPROHT.JPG'
                }
              },
              {
                name: 'Glasbewerking',
                href: '/glasbewerking',
                image: {
                  src: 'http://webshop.buvac.nl/acpecat/images/PTX802HT.JPG'
                }
              }
            ]
          },
          hero: {
            image: {
              src: 'https://images.unsplash.com/photo-1648815546030-6a5792d4fa38?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
            },
            content: `<div class='text-4xl weight-normal z-1 mb-2'>
              <p>BUVAC</p>
              <p class='text-3xl weight-bold text-light'>VANDAAG BESTELD, MORGEN GELEVERD</p>
            </div>`,
            primaryButton: {
              label: 'Brochures & prijslijsten',
              uppercase: true
            },
            secondaryButton: {
              label: 'Brochures & prijslijsten',
              uppercase: true
            }
          },
          affiliates: {
            title: `<h2 class='z-1'>
              <span class='text-orange'>MERKEN</span>
              <span>VAN</span>
              <span class='weight-normal'>BUVAC</span>
            </h2>`,
            items: [
              {
                src: 'assets/images/artifex_logo_4C.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              {
                src: 'assets/images/BOS-logo-laag-res.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              {
                src: 'assets/images/Carat-corporate-logo.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              {
                src: 'assets/images/Eisenblaetter_Logo_mitClaim-e1570014813744.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              { src: 'assets/images/Euro-Fintec.jpeg' },
              { src: 'assets/images/Fein-Grid.jpeg' },
              {
                src: 'assets/images/FEIN-logo.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              {
                src: 'assets/images/Flex_rot_Schutzraum.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              {
                src: 'assets/images/garryson_ver_7-01.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              { src: 'assets/images/Gecam.jpeg' },
              { src: 'assets/images/Haspa-signet.jpeg' },
              { src: 'assets/images/HT-Logo-neu-2017-1.jpeg' },
              { src: 'assets/images/Kef-logo-blaa-pantone.jpeg' },
              { src: 'assets/images/KEMPER-LOGO-3D-1.jpeg' },
              {
                src: 'assets/images/marpol-logo-1.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              {
                src: 'assets/images/NogaTools-Logo-B.jpeg',
                alt: 'Logo of Artifex',
                description:
                  'This is the logo of Artifex. Click to go to their website',
                href: 'https://www.buvac.nl/'
              },
              { src: 'assets/images/Osborn_premium_logo_cmyk.jpeg' },
              {
                src: 'assets/images/Picard-LOGO_ohne_Kreis-scaled-e1616497154951.jpeg'
              },
              { src: 'assets/images/Sait-LOGO-ORIGINALE.jpeg' },
              { src: 'assets/images/Suhner-Logo-Vector.jpeg' },
              { src: 'assets/images/Tyrolit_Logo_Cyan.jpeg' },
              { src: 'assets/images/WESPA_Logo_bg_white.jpeg' }
            ]
          },
          footerLinks: [
            {
              name: 'Kennis en service',
              href: '#'
            },
            {
              name: 'Kwaliteit en garantie',
              href: '#'
            },
            {
              name: 'Snelle levering',
              href: '#'
            },
            {
              name: 'Disclaimer Buvac',
              href: '#'
            },
            {
              name: 'Privacy statement Buvac',
              href: '#'
            },
            {
              name: 'Direct inloggen',
              href: '#'
            }
          ],
          categoryHead: {
            content: `<div>
              <h1 class='text-4xl weight-normal mb-2'>Schuren</h1>
              <p class=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>`,
            image: {
              src: 'https://images.unsplash.com/photo-1517437702514-0bdaa494a0b9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            breadcrumbs: [
              {
                name: 'Schuren',
                href: '/schuren'
              },
              {
                name: 'Fiber',
                href: '/fiber'
              }
            ]
          },
          subCategories: [
            {
              name: 'Fiber schuurschijven',
              href: '/schuren/fiber-schuurschijven',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/imggroepen/0152.jpg'
              }
            },
            {
              name: 'Velcro klittenbandschijven',
              href: '/schuren/velcro-klittenbandschijven',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/imggroepen/0760.jpg'
              }
            },
            {
              name: 'Schuurlinnen',
              href: '/schuren/schuurlinnen',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/images/SAIT-SSX.jpg'
              }
            },
            {
              name: 'Schuurpapier',
              href: '/schuren/schuurpapier',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/images/SAIT-SSC.jpg'
              }
            },
            {
              name: 'Vliesmateriaal',
              href: '/schuren/vliesmateriaal',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/imggroepen/1001.jpg'
              }
            }
          ],
          subCategoriesFeed: [
            {
              name: 'Fiber schuurschijven',
              href: '/schuren/fiber-schuurschijven',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/imggroepen/01.jpg'
              },
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
              name: 'Velcro klittenbandschijven',
              href: '/schuren/velcro-klittenbandschijven',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/imggroepen/07.jpg'
              },
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
              name: 'Schuurlinnen',
              href: '/schuren/schuurlinnen',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/imggroepen/04.jpg'
              },
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
              name: 'Schuurpapier',
              href: '/schuren/schuurpapier',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/imggroepen/05.jpg'
              },
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
              name: 'Vliesmateriaal',
              href: '/schuren/vliesmateriaal',
              image: {
                src: 'http://webshop.buvac.nl/acpecat/imggroepen/10.jpg'
              },
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            }
          ]
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/app';

  .z-1 {
    position: relative;
    z-index: 1;
  }

  .text-orange {
    color: var(--primaryColor);
  }

  .mb-2 {
    margin-bottom: calc(2 * 4px);
  }
</style>
