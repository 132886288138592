var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative w-fit-content h-fit-content"},[(_vm.link || _vm.href)?_c('a',{staticClass:"button",class:( _obj = {
      disabled: _vm.disabled,
      dense: _vm.dense,
      wide: _vm.wide,
      square: _vm.square,
      uppercase: _vm.uppercase
    }, _obj[("bg-" + _vm.backgroundColor)] = _vm.backgroundColor, _obj[_vm.color] = _vm.color, _obj ),attrs:{"href":_vm.href}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_c('button',{staticClass:"button",class:( _obj$1 = {
      dense: _vm.dense,
      wide: _vm.wide,
      square: _vm.square,
      uppercase: _vm.uppercase
    }, _obj$1[("bg-" + _vm.backgroundColor)] = _vm.backgroundColor, _obj$1[_vm.color] = _vm.color, _obj$1 ),attrs:{"form":_vm.form,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('span',[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2)]),_c('span',{class:( _obj$2 = {}, _obj$2[("shadow-" + _vm.shadowColor)] = _vm.shadowColor, _obj$2 )})])}
var staticRenderFns = []

export { render, staticRenderFns }